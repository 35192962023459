const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://zus1nb0h17.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://l7jzalp8ai.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.11.2',
    HOSTNAME: 'https://teams-service.staging.laika.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.laika.forwoodsafety.com',
    WEBSOCKET: 'wss://3bugctsqx1.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;
